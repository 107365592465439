import React from 'react';
import InProgress from '../../components/templates/InProgressLayout';

const DeFi: React.FC = () => {

  return (
    <InProgress />
  )
}

export default DeFi